'use client';

import React from 'react';
import { styled } from '@linaria/react';

import type { ContentData } from '@/types/content.types';

import Link from '@/components/Link';
import IconArrowBullet from '@/components/Icons/IconArrowBullet';

// We only require a few fields from ContentData.
type ContentDataSubset = Pick<
  ContentData,
  'slug' | 'title' | 'pathname'
>;

interface Props {
  articles: Array<ContentDataSubset>;
}

function PopularArticleList({ articles }: Props) {
  const [hoveredArticleSlug, setHoveredArticleSlug] = React.useState<
    string | null
  >(null);

  return (
    <Wrapper>
      {articles.map((contentData) => {
        return (
          <li key={contentData.slug}>
            <ContentLink
              href={contentData.pathname}
              onMouseEnter={() =>
                setHoveredArticleSlug(contentData.slug)
              }
              onMouseLeave={() => setHoveredArticleSlug(null)}
            >
              <Bullet
                isHovering={contentData.slug === hoveredArticleSlug}
              />
              {contentData.title}
            </ContentLink>
          </li>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
  padding: 0;
  margin-left: -36px;
`;

const ContentLink = styled(Link)`
  display: flex;
  gap: 16px;
  color: inherit;
  font-size: 1.1875rem;
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  /* Not visible except for focus outline: */
  border-radius: 4px;

  &:focus-visible {
    outline-offset: 4px;
  }

  &:hover {
    color: var(--color-primary);
  }
`;

const Bullet = styled(IconArrowBullet)`
  margin-top: 4px;
`;

export default PopularArticleList;
