'use client';

import React from 'react';
import { styled } from '@linaria/react';
import { ExternalLink } from 'react-feather';

import type { ContentData } from '@/types/content.types';

import Link from '@/components/Link';
import VisuallyHidden from '@/components/VisuallyHidden';
import PoppingArrows from './PoppingArrows';

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  contentData: ContentData;
}

function ContentPreview({ contentData, ...delegated }: Props) {
  const {
    title,
    subtitle,
    abstract,
    pathname,
    externalUrl,
    externalLabel,
  } = contentData;

  const [isHoveringOverCard, setIsHoveringOverCard] =
    React.useState(false);

  const isExternal = !!externalLabel;

  const href = isExternal ? externalUrl : pathname;

  return (
    <Wrapper
      {...delegated}
      onMouseEnter={() => setIsHoveringOverCard(true)}
      onMouseLeave={() => setIsHoveringOverCard(false)}
    >
      <Title href={href}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
        {isExternal && <StyledExternalLink size={18} />}
      </Title>
      {subtitle && <Subheading>{subtitle}</Subheading>}

      <Excerpt>{abstract}</Excerpt>

      <PoppingArrows
        href={href}
        shouldShowFirstArrow={isHoveringOverCard}
      >
        {isExternal ? (
          `Read on ${externalLabel}`
        ) : (
          <>
            Read more
            <VisuallyHidden>: {title}</VisuallyHidden>
          </>
        )}
      </PoppingArrows>
    </Wrapper>
  );
}

const Wrapper = styled.article`
  position: relative;
  display: block;
  text-decoration: none;
  color: var(--color-text);
`;

const Title = styled(Link)`
  display: flex;
  align-items: baseline;
  gap: 12px;
  width: fit-content;
  padding-inline: 4px;
  margin-inline: -4px;
  border-radius: 2px;
  color: inherit;
  text-decoration: none;
  font-size: 1.375rem;
  font-weight: var(--font-weight-bold);
  outline-offset: 4px;
  outline-color: var(--color-primary);

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: var(--color-primary);
    text-underline-offset: 2px;
  }

  /*
    I can add certain HTML elements to the title, in the markdown.
    NOTE: This code is semi-duplicated in /components/BlogPostTitle
  */
  code {
    position: relative;
    font-family: var(--font-family-mono);
    display: inline-block;
    background: var(--color-cloud-300);
    padding-right: 0.25rem;
    border-radius: 4px;
  }
  &:hover code::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0.25rem;
    border-bottom: 2px solid var(--color-primary);
  }

  html[data-color-mode='dark'] & code {
    background: var(--color-gray-200);
  }
`;

const Subheading = styled.p`
  color: var(--color-gray-600);
  font-weight: var(--font-weight-medium);
  font-size: calc(17 / 16 * 1rem);
`;

const Excerpt = styled.p`
  font-size: 1rem;
  margin-block: 16px;
  white-space: pre-wrap;
  text-wrap: pretty;
`;

const StyledExternalLink = styled(ExternalLink)`
  color: var(--color-gray-500);
  transform: translateY(1.5px);
`;

export default ContentPreview;
