import React from 'react';

import { throttle } from '@/utils';

interface ScrollPosition {
  x: number | null;
  y: number | null;
}

const useScrollPosition = (throttleBy = 500) => {
  const [scrollPosition, setScrollPosition] =
    React.useState<ScrollPosition>({
      x: null,
      y: null,
    });

  React.useEffect(() => {
    const handleScroll = throttle(() => {
      setScrollPosition({ x: window.scrollX, y: window.scrollY });
    }, throttleBy);

    // Calculate the initial scroll position:
    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [throttleBy]);

  return [scrollPosition.x, scrollPosition.y];
};

export default useScrollPosition;
