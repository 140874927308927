import * as React from 'react';
import { styled } from '@linaria/react';
import { animated } from 'react-spring';

import useBoop from '@/hooks/use-boop';

export interface Props extends React.ComponentProps<'button'> {
  Icon: any;
  backgroundColor?: string;
  highlightColor?: string;
  iconSide?: 'left' | 'right';
  iconProps?: any;
  boopEffect?: any;
  discouraged?: boolean;
  href?: string;
  textAlign?: 'center' | 'true-center';
  children: React.ReactNode;
}

const ButtonPlayful = React.forwardRef<HTMLButtonElement, Props>(
  function ButtonPlayful(
    {
      Icon,
      iconSide = 'left',
      iconProps = {},
      backgroundColor = 'var(--color-gray-900)',
      highlightColor = 'var(--color-gray-800)',
      boopEffect = { rotation: 10 },
      children,
      style = {},
      discouraged = false,
      href,
      textAlign = 'center',
      ...delegated
    }: Props,
    ref
  ) {
    const [boopStyle, trigger] = useBoop(boopEffect);

    return (
      <Wrapper
        ref={ref as any}
        onMouseEnter={() => {
          trigger();
        }}
        style={{
          '--background-color': backgroundColor,
          ...style,
        }}
        data-discouraged={discouraged}
        // @ts-ignore
        href={href}
        as={typeof href === 'string' ? 'a' : 'button'}
        {...delegated}
      >
        {Icon && (
          <IconCol
            style={{
              order: iconSide === 'left' ? 1 : 3,
            }}
          >
            <IconBox
              style={{
                '--background-color': highlightColor,
              }}
            >
              <IconWrapper style={boopStyle}>
                <Icon size={20} {...iconProps} />
              </IconWrapper>
            </IconBox>
          </IconCol>
        )}
        {children && (
          <ChildWrapper style={{ order: 2 }}>{children}</ChildWrapper>
        )}
        {textAlign === 'true-center' && (
          <Col style={{ order: iconSide === 'left' ? 3 : 1 }} />
        )}
      </Wrapper>
    );
  }
);

interface WrapperProps {
  style: React.CSSProperties;
}

const Wrapper = styled.button<WrapperProps>`
  --height: 2.625rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: var(--background-color);
  color: var(--text-color, white);
  height: var(--height);
  font-size: 1rem;
  text-decoration: none;

  &:focus {
    outline-offset: 3px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.325;
  }

  &[data-discouraged='true'] {
    cursor: not-allowed;
    filter: contrast(0.75) brightness(0.75);
  }
`;

const Col = styled.span`
  position: relative;
  height: var(--height);
  width: var(--height);
`;

const IconCol = styled(Col)`
  --indent-by: 0.8;

  transition: transform 200ms cubic-bezier(0, 0.67, 0.6, 1.24);

  ${Wrapper}:active & {
    transform: scale(var(--indent-by));
    transition: transform 0ms;
  }

  svg {
    display: block;
    transition: transform 300ms cubic-bezier(0, 0.67, 0.6, 1.24);

    ${Wrapper}:active & {
      transform: scale(calc(1 / var(--indent-by)));
      transition: transform 0ms;
    }
  }
`;

const IconBox = styled.span<WrapperProps>`
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  display: grid;
  place-content: center;
  border-radius: 2px;
  background: var(--background-color);
`;

const IconWrapper = styled(animated.span)`
  display: block;
`;

const ChildWrapper = styled.span`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  padding-right: calc(16px + 4px);
  font-weight: var(--font-weight-medium);
  font-size: inherit;
  white-space: nowrap;
`;

export default ButtonPlayful;
