import React from 'react';
import { styled } from '@linaria/react';

import useSound from '@/hooks/use-sound';

import Link from '@/components/Link';

const ARROW_DELAY = 125;

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  href?: string;
  shouldShowFirstArrow: boolean;
  increaseTargetBy?: number;
  children: React.ReactNode;
}

function PoppingArrows({
  href,
  shouldShowFirstArrow,
  increaseTargetBy = 0,
  children,
  ...delegated
}: Props) {
  const [isHovering, setIsHovering] = React.useState(false);

  const [playRising, { stop }] = useSound('/sounds/rising-pops.mp3', {
    volume: 0.25,
  });

  return (
    <Wrapper
      href={href}
      // This is a secondary, complementary link. For keyboard users, the ContentPreview’s <Title> serves as the main focusable link.
      tabIndex={-1}
      onMouseEnter={() => {
        setIsHovering(true);
        playRising();
      }}
      onMouseLeave={() => {
        setIsHovering(false);
        stop();
      }}
      {...delegated}
    >
      <TargetBump
        style={{
          top: -increaseTargetBy,
          left: -increaseTargetBy,
          right: -increaseTargetBy,
          bottom: -increaseTargetBy,
        }}
      />
      {children}
      <ArrowWrapper>
        <ArrowSvg
          width="36"
          height="12"
          viewBox="0 0 36 12"
          fill="none"
        >
          <path
            d="M0.75 6H11.25 M6 0.75L11.25 6L6 11.25"
            style={{
              opacity: shouldShowFirstArrow ? 1 : 0,
              transition: `opacity ${isHovering ? 0 : ARROW_DELAY}ms`,
            }}
          />

          <path
            d="M15 10L19.5 5.5L15 1"
            style={{
              opacity: isHovering ? 1 : 0,
              transition: `opacity ${isHovering ? 0 : ARROW_DELAY}ms`,
            }}
          />
          <path
            d="M23 10L27.5 5.5L23 1"
            strokeOpacity="0.66"
            style={{
              opacity: isHovering ? 1 : 0,
              transition: `opacity ${
                isHovering ? 0 : ARROW_DELAY
              }ms ${ARROW_DELAY}ms`,
            }}
          />
          <path
            d="M31 10L35.5 5.5L31 1"
            strokeOpacity="0.35"
            style={{
              opacity: isHovering ? 1 : 0,
              transition: `opacity ${
                isHovering ? 0 : ARROW_DELAY
              }ms ${ARROW_DELAY * 2}ms`,
            }}
          />
        </ArrowSvg>
      </ArrowWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  font-weight: var(--font-weight-bold);
  color: inherit;
  text-decoration: none;

  &:focus-visible {
    outline: none;
  }
`;

const ArrowWrapper = styled.div`
  position: relative;
  margin-left: 8px;
`;

const ArrowSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-6px);
  max-width: unset;

  path {
    stroke: var(--color-primary);
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`;

const TargetBump = styled.div`
  position: absolute;
`;

export default PoppingArrows;
