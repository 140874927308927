'use client';

import React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

import { BREAKPOINTS } from '@/constants';

import JoshMascot from '@/components/JoshMascot';

function UpsideDownJosh() {
  const [offset, setOffset] = React.useState<number>(-216);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const target = wrapperRef.current;

    if (!target) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio < 0.2) {
          setOffset(-226);
        } else if (entry.intersectionRatio < 0.5) {
          setOffset(-150);
        } else {
          setOffset(-82);
        }
      },
      {
        threshold: [0.2, 0.5],
      }
    );

    observer.observe(target);

    return () => {
      if (target) {
        observer.disconnect();
      }
    };
  }, []);

  const style = useSpring({
    '--offset': `${offset}px`,
    config: {
      tension: 160,
      friction: 32,
    },
  });

  return (
    <MascotWrapper ref={wrapperRef}>
      <Slider style={style}>
        <JoshMascot mood={offset < -100 ? 'happy' : 'very-happy'} />
      </Slider>
    </MascotWrapper>
  );
}

const MascotWrapper = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: clip;
`;

const Slider = styled(animated.div)`
  position: absolute;
  left: 50%;
  transform: translate(-533px, var(--offset)) rotate(180deg);
  will-change: transform;

  @media (max-width: 67.5rem) {
    left: 0;
    transform: translateY(-82px) rotate(180deg);
  }
  @media ${BREAKPOINTS.smAndSmaller} {
    display: none;
  }
`;

export default UpsideDownJosh;
