'use client';

import * as React from 'react';
import { styled } from '@linaria/react';
import { ArrowDown } from 'react-feather';

import type { ContentData } from '@/types/content.types';

import ContentPreview from '@/components/ContentPreview';
import ButtonPlayful from '@/components/ButtonPlayful';

interface Props {
  articles: Array<ContentData>;
}

const INITIAL_ARTICLE_COUNT = 12;

function AllContentList({ articles }: Props) {
  const [hasExpanded, setHasExpanded] = React.useState(false);

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const initialArticles = articles.slice(0, INITIAL_ARTICLE_COUNT);

  const remainingArticles = hasExpanded
    ? articles.slice(INITIAL_ARTICLE_COUNT)
    : undefined;

  return (
    <Wrapper ref={wrapperRef}>
      {initialArticles.map((contentData) => {
        return (
          <ContentPreview
            key={contentData.slug}
            contentData={contentData}
          />
        );
      })}
      {remainingArticles ? (
        <RemainingArticles>
          {remainingArticles.map((contentData) => {
            return (
              <ContentPreview
                key={contentData.slug}
                contentData={contentData}
              />
            );
          })}
        </RemainingArticles>
      ) : (
        <ExpandWrapper>
          <ExpandBtn
            Icon={ArrowDown}
            onClick={() => {
              setHasExpanded(true);
            }}
            boopEffect={{
              y: 3,
            }}
            backgroundColor="var(--button-bg)"
            highlightColor="var(--button-bg-highlight)"
          >
            Show more
          </ExpandBtn>
        </ExpandWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  scroll-margin-top: 96px;
`;

const ExpandWrapper = styled.div`
  position: relative;
  margin-top: -32px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 300px;
    background: linear-gradient(
      to top,
      var(--color-background) 0%,
      transparent 100%
    );
    pointer-events: none;
  }
`;

const ExpandBtn = styled(ButtonPlayful)`
  --button-bg: var(--color-gray-900);
  --button-bg-highlight: var(--color-gray-800);
  position: relative;
  width: 250px;
  max-width: 100%;
  margin: 0 auto;

  html[data-color-mode='dark'] & {
    --button-bg: var(--color-gray-200);
    --button-bg-highlight: var(--color-gray-300);
  }
`;

const RemainingArticles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  animation:
    entrez 900ms cubic-bezier(0.17, 0.67, 0.34, 1),
    fadeIn 500ms;

  @media (prefers-reduced-motion: reduce) {
    animation: fadeIn 500ms;
  }

  @keyframes entrez {
    from {
      transform: translateY(-32px);
    }
  }
`;

export default AllContentList;
