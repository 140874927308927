// NOTE: This component assumes it's being rendered in a square. Haven't tested how it would work in a rectangle.

import React from 'react';
import { styled } from '@linaria/react';
import { range } from '@/utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  numOfRows?: number;
  numOfCols?: number;
  axisLines?: boolean;
}

function DottedControlBackground({
  numOfRows = 12,
  numOfCols = 12,
  axisLines,
  ...delegated
}: Props) {
  const viewBoxSize = 200;

  const dots = range(numOfRows - 1).map((rowIndex) =>
    range(numOfCols - 1).map((colIndex) => {
      // If we're in the very middle of the grid, we want to skip
      // the circles, since we'll draw axis lines there.
      const isMiddleRow = rowIndex === numOfRows / 2 - 1;
      const isMiddleCol = colIndex === numOfCols / 2 - 1;

      if (axisLines && (isMiddleRow || isMiddleCol)) {
        return null;
      }

      return (
        <circle
          key={colIndex}
          cx={viewBoxSize * ((colIndex + 1) / numOfCols)}
          cy={viewBoxSize * ((rowIndex + 1) / numOfRows)}
          r={1}
          fill="var(--dot-color, var(--color-gray-300))"
          fillOpacity="var(--dot-opacity, 0.5)"
        />
      );
    })
  );

  return (
    <Svg viewBox="0 0 200 200" {...delegated}>
      {axisLines && (
        <>
          <Axis
            x1={0}
            y1={viewBoxSize / 2}
            x2={viewBoxSize}
            y2={viewBoxSize / 2}
          />
          <Axis
            x1={viewBoxSize / 2}
            y1={0}
            x2={viewBoxSize / 2}
            y2={viewBoxSize}
          />
        </>
      )}
      <g>{dots}</g>
    </Svg>
  );
}

const Svg = styled.svg`
  display: block;
  overflow: visible;
  width: 100%;
  height: 100%;
  border: 3px solid var(--border-color, var(--color-gray-300));
  border-radius: 6px;
`;

const Axis = styled.line`
  stroke: var(--axis-color, var(--color-gray-100));
  stroke-width: 2px;
`;

export default React.memo(DottedControlBackground);
