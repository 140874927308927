// By default, 1rem is equivalent to 16px, but this ratio can change depending on the user’s specified default font size. This custom hook allows us to check what the current value is. This is useful if, for example, I want to change the UI based on whether or not the user has bumped up their default font size
import React from 'react';

function useFontRatio() {
  const [ratio, setRatio] = React.useState(1);

  React.useEffect(() => {
    let tempElement: HTMLDivElement | null = null;

    function calculateRatio() {
      if (!tempElement) {
        tempElement = document.createElement('div');

        tempElement.style.fontSize = '1rem';

        document.body.appendChild(tempElement);
      }

      const computedFontSize = getComputedStyle(tempElement).fontSize;

      const fontSize = parseFloat(computedFontSize);

      setRatio(fontSize / 16);

      document.body.removeChild(tempElement);
    }

    calculateRatio();

    return () => {
      if (tempElement) {
        try {
          document.body.removeChild(tempElement);
        } catch (err) {
          // Sometimes this happens for unclear reasons. I need to investigate!
        }
      }
    };
  }, []);

  return ratio;
}

export default useFontRatio;
