/*
  Similar to the LayoutArticle, we have an empty box that sits behind the header and semi-blocks the content underneath from being spotted.
*/
'use client';

import * as React from 'react';
import { styled } from '@linaria/react';

import { HEADER_HEIGHT } from '@/constants';
import {
  DARK_COLORS_RAW,
  LIGHT_COLORS_RAW,
  vals,
} from '@/constants/colors';

function HeaderContentBlocker() {
  const [isStuck, setIsStuck] = React.useState(false);

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsStuck(entry.intersectionRatio < 1);
      },
      {
        root: null,
        threshold: [0, 1.0],
        rootMargin: `-8px 0px 0px 0px`,
      }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return <Wrapper ref={ref} data-is-stuck={String(isStuck)} />;
}

const Wrapper = styled.div`
  grid-area: blocker;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: ${HEADER_HEIGHT}rem;
  z-index: 1;
  opacity: 0;
  transition:
    background var(--color-swap-duration),
    opacity 300ms;
  pointer-events: none;

  &[data-is-stuck='true'] {
    opacity: 1;
  }

  html[data-color-mode='dark'] & {
    background: hsl(${vals(DARK_COLORS_RAW.colorBackground)} / 0.65);
  }
  html[data-color-mode='light'] & {
    background: hsl(${vals(LIGHT_COLORS_RAW.colorBackground)} / 0.9);
  }
`;

export default HeaderContentBlocker;
