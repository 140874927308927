import * as React from 'react';
import { styled } from '@linaria/react';

import { sampleOne } from '@/utils';
import useSound from '@/hooks/use-sound';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  label: string;
  color?: 'red' | 'gray';
}

function PushButton({ label, color = 'gray', ...delegated }: Props) {
  const id = React.useId();

  const [play, { stop }] = useSound(
    '/sounds/iidx-button-sprite.mp3',
    {
      sprite: {
        down1: [0, 180],
        up1: [200, 550],
        down2: [800, 180],
        up2: [1000, 180],
        down3: [1200, 180],
        up3: [1400, 180],
        down4: [1600, 180],
        up4: [1800, 180],
      },
    }
  );

  function handlePress() {
    stop();
    const soundId = sampleOne(['down1', 'down2', 'down3', 'down4']);
    play({ id: soundId });
  }

  function handleRelease() {
    stop();
    const soundId = sampleOne(['up1', 'up2', 'up3', 'up4']);
    play({ id: soundId });
  }

  return (
    <Wrapper
      style={{
        '--button-top-center':
          color === 'red'
            ? 'hsl(348deg 100% 60%)'
            : 'hsl(210deg 20% 90%)',
        '--button-top':
          color === 'red'
            ? 'hsl(348deg 90% 40%)'
            : 'hsl(210deg 20% 75%)',
        '--button-top-highlight':
          color === 'red'
            ? 'hsl(348deg 100% 70%)'
            : 'hsl(212deg 100% 95% / 0.6)',
        '--button-shaft-top':
          color === 'red'
            ? 'hsl(355deg 100% 30%)'
            : 'hsl(212deg 8% 60%)',
        '--button-shaft-bottom':
          color === 'red'
            ? 'hsl(355deg 80% 20%)'
            : 'hsl(212deg 8% 50%)',
      }}
    >
      <Label htmlFor={id}>
        <LabelText>{label}</LabelText>
      </Label>
      <ButtonWrapper>
        <Hole>
          <ButtonShaft />
        </Hole>
        <Button
          id={id}
          {...delegated}
          onPointerDown={() => {
            handlePress();

            window.addEventListener('pointerup', handleRelease, {
              once: true,
            });
          }}
        >
          <ButtonTop />
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 6rem;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  font-family: var(--font-family-mono);
  text-transform: uppercase;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 1px;
    margin-block: auto;
    background: var(--control-gray-100);
  }
`;

const LabelText = styled.span`
  position: relative;
  background: var(--color-background);
  padding-inline: 6px;
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 2rem;
`;

const Hole = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(
    to bottom,
    var(--control-gray-500),
    var(--control-gray-900)
  );
  /* Trim the shaft */
  overflow: hidden;
  overflow: clip;
`;

const ButtonShaft = styled.span`
  position: absolute;
  left: 2px;
  right: 2px;
  bottom: 0;
  display: block;
  width: calc(100% - 4px);
  height: 10px;
  background: linear-gradient(
    to bottom,
    var(--button-shaft-top) 82%,
    var(--button-shaft-bottom) 100%
  );
`;

const Button = styled.button`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

const ButtonTop = styled.span`
  --y: -3px;
  position: absolute;
  left: 2px;
  right: 2px;
  bottom: 0px;
  width: calc(100% - 4px);
  height: 100%;
  background: radial-gradient(
    circle at top center,
    var(--button-top-center),
    var(--button-top)
  );

  border-radius: 4px;
  transform: translateY(var(--y));
  transition: transform 300ms;
  /* Trim the blurry pseudo-element */
  overflow: hidden;
  overflow: clip;

  &:hover {
    --y: -5px;
  }

  &:active {
    --y: -1px;
    transition: transform 0ms;
  }

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    right: 2px;
    height: 25%;
    background: linear-gradient(
      to bottom,
      var(--button-top-highlight),
      transparent
    );
    border-radius: 3px 3px 50% 50%;
    opacity: 0.6;
    filter: blur(1px);
  }
`;

export default PushButton;
