import * as React from 'react';
import { styled } from '@linaria/react';
import FocusLock from 'react-focus-lock';

import ColorModeContainer from '@/components/ColorModeContainer';
import IconDismiss from '@/components/Icons/IconDismiss';

import { useArt } from './ArtProvider';
import ArtSlider from './ArtSlider';
import ArtToggleButtonGroup from './ArtToggleButtonGroup';
import ArtXYPad from './ArtXYPad';
import PushButton from './PushButton';
import type { Linecap, Shape } from '@/types/art.types';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  handleDismiss: () => void;
  onRandomize: () => void;
}

const XY_SIZE = 206;

function EditPanel({
  handleDismiss,
  onRandomize,
  ...delegated
}: Props) {
  const { state, numOfConnections, actions } = useArt();
  const [dismissBtnStatus, setDismissBtnStatus] = React.useState<
    'idle' | 'booped' | 'pressed' | 'dismissed'
  >('idle');

  const panelId = React.useId().replace(/:/g, '-');

  React.useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        handleDismiss();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    if (dismissBtnStatus !== 'booped') {
      return;
    }

    const timeoutId = window.setTimeout(() => {
      setDismissBtnStatus('idle');
    }, 150);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [dismissBtnStatus]);

  // Should be impossible:
  if (!state) {
    return;
  }

  return (
    <FocusLock returnFocus={true}>
      <Wrapper
        colorMode="light"
        style={{
          opacity: dismissBtnStatus === 'dismissed' ? 0 : 1,
        }}
        {...delegated}
      >
        <Header>
          <h2>Rainbow Configurator</h2>

          <CloseButton
            onClick={() => {
              setDismissBtnStatus('dismissed');

              window.setTimeout(() => {
                handleDismiss();
              }, 300);
            }}
            onMouseEnter={() => setDismissBtnStatus('booped')}
            onMouseLeave={() => setDismissBtnStatus('idle')}
            onMouseDown={() => setDismissBtnStatus('pressed')}
            onMouseUp={() => setDismissBtnStatus('idle')}
          >
            <IconDismiss
              isPressed={dismissBtnStatus === 'pressed'}
              isBooped={dismissBtnStatus === 'booped'}
            />
          </CloseButton>
        </Header>
        <Content>
          <Column>
            <ArtSlider
              id={`${panelId}-density`}
              value={state.density}
              min={0}
              max={100}
              step={1}
              label="Density"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                actions.tweakValue({
                  density: Number(ev.target.value),
                })
              }
            />

            <ArtSlider
              id={`${panelId}-numOfRows`}
              value={state.numOfRows}
              min={3}
              max={10}
              step={1}
              label="Num of Rows"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                actions.tweakValue({
                  numOfRows: Number(ev.target.value),
                })
              }
              stage={2}
            />

            <Row>
              <Buttons
                label="Operation"
                options={[
                  { label: 'modern', value: 'line' },
                  { label: 'classic', value: 'circle' },
                ]}
                value={state.shape}
                handleSelect={(value) =>
                  actions.tweakValue({ shape: value as Shape })
                }
              />
              <Buttons
                label="Edges"
                options={['round', 'square']}
                value={state.linecap}
                handleSelect={(value) =>
                  actions.tweakValue({ linecap: value as Linecap })
                }
              />
            </Row>
          </Column>
          <Column>
            <ArtXYPad
              id={`${panelId}-line-props`}
              label="Segment Values"
              width={XY_SIZE}
              height={XY_SIZE}
              x={state.lineLength}
              y={state.lineWidth}
              numOfRows={10}
              numOfCols={10}
              onChange={({ x, y }) => {
                actions.tweakValue({
                  lineLength: x,
                  lineWidth: y,
                });
              }}
            />
          </Column>
        </Content>
        <Footer>
          <Note>
            This panel allows you to tweak the art.{' '}
            <strong>
              These changes are applied for all users on the site.
            </strong>{' '}
            {numOfConnections === 1 ? (
              'You’re the only one editing the art right now.'
            ) : (
              <>
                There {numOfConnections === 2 ? 'is' : 'are'}{' '}
                <strong>
                  {numOfConnections - 1} other{' '}
                  {numOfConnections === 2 ? 'person' : 'people'}
                </strong>{' '}
                here right now.
              </>
            )}
          </Note>
          <Actions>
            <PushButton
              label="Random"
              onClick={() => {
                actions.randomizeValues();
                onRandomize();
              }}
            />
            <PushButton
              label="Reset"
              color="red"
              onClick={actions.resetValues}
            />
          </Actions>
        </Footer>
      </Wrapper>
    </FocusLock>
  );
}

const Wrapper = styled(ColorModeContainer)`
  --panel-padding: 4px;
  --content-padding: 12px;
  --control-gray-900: hsl(212deg 20% 10%);
  --control-gray-800: hsl(212deg 18% 20%);
  --control-gray-700: hsl(212deg 15% 25%);
  --control-gray-500: hsl(212deg 12% 40%);
  --control-gray-300: hsl(212deg 8% 60%);
  --control-gray-100: hsl(212deg 10% 80%);
  --control-handle: white;

  width: 100%;
  max-width: 38.75rem;
  padding: var(--panel-padding);
  padding-top: 0;
  pointer-events: auto;
  animation: fadeIn 300ms;
  transition: opacity 200ms 100ms;

  @media (max-width: 43.75rem) {
    display: none;
  }

  html[data-color-mode='light'] & {
    box-shadow:
      0px 1px 2px hsl(210deg 20% 20% / 0.1),
      0px 2px 4px hsl(210deg 20% 20% / 0.1),
      0px 5px 10px hsl(210deg 20% 20% / 0.1),
      0px 15px 30px hsl(210deg 20% 20% / 0.1);
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-300);

  h2 {
    font-size: 1.25rem;
    padding: var(--content-padding);
    font-family: var(--font-family-mono);
  }
`;

const CloseButton = styled.button`
  padding: var(--content-padding);
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr calc(${XY_SIZE / 16}rem + 5px * 2);
  gap: 12px;
  padding: var(--content-padding);
  animation: fadeIn 300ms 200ms both;
`;

const Row = styled.div`
  display: flex;
  gap: var(--content-padding);
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--content-padding);
`;

const Buttons = styled(ArtToggleButtonGroup)`
  flex: 1;
`;

const Footer = styled.footer`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 16px;
  padding: var(--content-padding);
  border-top: 1px solid var(--color-gray-300);
`;

const Note = styled.p`
  font-family: var(--font-family-mono);
  font-size: 0.75rem;
`;

const Actions = styled.div`
  display: flex;
  gap: 12px;
`;

export default EditPanel;
