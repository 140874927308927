import { styled } from '@linaria/react';

import useHasHydrated from '@/hooks/use-has-hydrated';

function BasicDoors() {
  const hasHydrated = useHasHydrated();

  const baseDelay = hasHydrated ? 400 : 1000;
  const outerDelay = baseDelay + 300;

  return (
    <Wrapper>
      <CoverShadow style={{ animationDelay: `${outerDelay}ms` }} />

      <LeftWrapper style={{ animationDelay: `${outerDelay}ms` }}>
        <InnerDoorLeft style={{ animationDelay: `${baseDelay}ms` }} />
        <OuterDoorLeft />
      </LeftWrapper>
      <RightWrapper style={{ animationDelay: `${outerDelay}ms` }}>
        <InnerDoorRight
          style={{ animationDelay: `${baseDelay}ms` }}
        />
        <OuterDoorRight />
      </RightWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  --duration: 700ms;
  --curve: cubic-bezier(0.66, 0, 0.31, 1.01);

  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow: clip;
  border-radius: 3px;
`;

const CoverShadow = styled.div`
  position: absolute;
  inset: 0;
  background: hsl(212deg 20% 6%);
  animation: fadeOut 1200ms both;
  pointer-events: none;
`;

const DoorWrapper = styled.div`
  position: absolute;
  width: calc(50% - 1px);
  height: 100%;
  top: 0;
  bottom: 0;
  overflow: hidden;
  overflow: clip;
`;

const LeftWrapper = styled(DoorWrapper)`
  --slide-val: calc(-50% - 2px);
  animation: slideToHorizontal var(--duration) var(--curve) both;
  animation-delay: 500ms;
`;
const RightWrapper = styled(DoorWrapper)`
  --slide-val: calc(50% + 2px);
  right: 0;
  animation: slideToHorizontal var(--duration) var(--curve) both;
  animation-delay: 500ms;
`;

const Door = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  outline: 1px solid var(--color-gray-400);
  background: var(--color-background);

  pointer-events: none;
`;

const InnerDoorLeft = styled(Door)`
  --slide-val: calc(-100% - 2px);
  left: 50%;
  border-radius: 0 4px 4px 0;
  animation: slideToHorizontal 500ms var(--curve) both;
`;
const OuterDoorLeft = styled(Door)`
  left: 0;
  border-radius: 0px 6px 6px 0px;
  box-shadow:
    1px 0px 2px hsl(0deg 0% 0% / 0.2),
    2px 0px 4px hsl(0deg 0% 0% / 0.2);
`;

const InnerDoorRight = styled(Door)`
  --slide-val: calc(100% + 2px);

  right: 50%;
  border-radius: 4px 0px 0px 4px;
  animation: slideToHorizontal 500ms var(--curve) both;
`;
const OuterDoorRight = styled(Door)`
  right: 0;
  border-radius: 6px 0px 0px 6px;
  box-shadow:
    -1px 0px 2px hsl(0deg 0% 0% / 0.2),
    -2px 0px 4px hsl(0deg 0% 0% / 0.2);
`;

export default BasicDoors;
