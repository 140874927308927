'use client';

import React from 'react';
import { styled } from '@linaria/react';

import ControlLabel from './ControlLabel';
import BasicDoors from './BasicDoors';

interface FullOption {
  label: string;
  value: string;
}
type Option = string | FullOption;

export interface Props
  extends React.HTMLAttributes<HTMLLabelElement> {
  label?: string;
  options: Array<Option>;
  value: string | Record<string, boolean>;
  handleSelect: React.Dispatch<React.SetStateAction<string>>;
}

function ArtToggleButtonGroup({
  label,
  options,
  value,
  handleSelect,
  ...delegated
}: Props) {
  const isMultiSelect = getIsMultiSelect(value);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const buttonRefs = React.useRef<Array<HTMLButtonElement>>([]);
  const formattedOptions = options.map((opt) => {
    return typeof opt === 'object'
      ? opt
      : {
          label: opt,
          value: opt,
        };
  });

  return (
    <ControlLabel {...delegated}>
      {label}
      <Row>
        {formattedOptions.map((option, index) => {
          const isSelected = isMultiSelect
            ? value[option.value] === true
            : value === option.value;

          return (
            <ToggleButton
              key={option.value}
              ref={(elem) => {
                if (elem) {
                  buttonRefs.current[index] = elem;
                }
              }}
              value={option.value}
              data-is-selected={isSelected}
              tabIndex={index === selectedIndex ? undefined : -1}
              onClick={() => {
                handleSelect(option.value);
                setSelectedIndex(index);
              }}
              onKeyDown={(event) => {
                let nextIndex;

                // Filter out any options that are hidden with CSS
                const validOptions = formattedOptions.filter(
                  (_, index) => {
                    const button = buttonRefs.current[index];

                    if (!button) {
                      return false;
                    }
                    const style = window.getComputedStyle(button);
                    return style?.display !== 'none';
                  }
                );

                if (event.code === 'ArrowLeft') {
                  nextIndex = selectedIndex - 1;

                  // Wrap around:
                  if (nextIndex < 0) {
                    nextIndex = validOptions.length - 1;
                  }
                } else if (event.code === 'ArrowRight') {
                  nextIndex = selectedIndex + 1;

                  if (nextIndex >= validOptions.length) {
                    nextIndex = 0;
                  }
                }

                if (typeof nextIndex === 'number') {
                  // Move focus
                  buttonRefs.current[nextIndex]?.focus();

                  // In single-select mode, change the selected option
                  if (!isMultiSelect) {
                    handleSelect(formattedOptions[nextIndex].value);
                  }

                  setSelectedIndex(nextIndex);
                }
              }}
              style={{
                color: isSelected ? 'white' : 'hsl(210deg 25% 60%)',
              }}
            >
              <Text>{option.label}</Text>
            </ToggleButton>
          );
        })}
        <BasicDoors stage={3} />
      </Row>
    </ControlLabel>
  );
}

function getIsMultiSelect(
  value: any
): value is Record<string, boolean> {
  return typeof value === 'object' && value !== null;
}

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 0px;
  width: 100%;
  height: 2.25rem;
  background: var(--control-gray-900);
  border-radius: 4px;
  border: 1px solid var(--color-gray-400);
  /* Block the doors from overflowing */
  overflow: hidden;
  overflow: clip;

  &:has(:focus-visible) {
    outline: 3px auto var(--color-primary);
    outline-offset: 3px;
  }
`;

const ToggleButton = styled.button`
  flex: 1;
  position: relative;
  padding: 4px;
  color: var(--color-text);
  font-size: 0.875rem;
  color: white;
  white-space: nowrap;

  &:focus-visible {
    outline: none;
  }
`;

const Text = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;

  ${ToggleButton}[data-is-selected="false"] &:hover {
    background: var(--control-gray-800);
  }

  ${ToggleButton}[data-is-selected="true"] & {
    background: var(--control-gray-700);

    &:hover {
      background: var(--control-gray-500);
    }
  }
`;

export default React.memo(ArtToggleButtonGroup);
