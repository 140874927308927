import * as React from 'react';

import { useDevicePixelRatio } from '@/helpers/canvas.helpers';

import GenerativeArt from '@/components/GenerativeArt';

import { useArt } from './ArtProvider';

function ConnectedGenerativeArt(props: {
  width: number;
  height: number;
  enableInteractiveFeatures: boolean;
  disableMouseTracking: boolean;
  isEditing: boolean;
}) {
  const { state } = useArt();

  const devicePixelRatio = useDevicePixelRatio();

  if (!state) {
    return null;
  }

  return (
    <GenerativeArt
      // If the user zooms in/out, it busts up the canvas. So, we'll listen for changes to this value, and re-mount the whole thing. This does mean the rainbow gets redrawn when you zoom, but it's better than the alternative.
      key={devicePixelRatio}
      devicePixelRatio={devicePixelRatio}
      {...state}
      {...props}
    />
  );
}

export default ConnectedGenerativeArt;
