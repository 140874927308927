import * as React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';
import { Settings } from 'react-feather';

import useSound from '@/hooks/use-sound';

import VisuallyHidden from '@/components/VisuallyHidden';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  ref?: React.Ref<HTMLButtonElement>;
  isEditing: boolean;
}
export const MIN_DISTANCE_TO_TOP = 120;

function EditButton({ isEditing, ...delegated }: Props) {
  const [rotation, setRotation] = React.useState(0);
  const [scale, setScale] = React.useState(1);

  const isPressed = scale !== 1;

  const spring = useSpring({
    transform: `rotate(${rotation}deg) scale(${scale})`,
    config: {
      clamp: false,
      tension: isPressed ? 600 : 300,
      friction: isPressed ? 10 : 30,
    },
  });

  const [playActive] = useSound('/sounds/pop-down.mp3', {
    volume: 0.25,
  });
  const [playOn] = useSound('/sounds/pop-up-on.mp3', {
    volume: 0.25,
  });
  const [playOff] = useSound('/sounds/pop-up-off.mp3', {
    volume: 0.25,
  });

  return (
    <Wrapper
      {...delegated}
      data-pressed={isPressed}
      onMouseDown={() => {
        setRotation(rotation - 20);
        setScale(0.875);
        playActive();
      }}
      onMouseUp={() => {
        setRotation(rotation + 160);
        setScale(1);

        if (isEditing) {
          playOff();
        } else {
          playOn();
        }
      }}
    >
      <IconWrapper style={spring}>
        <Settings size={20} />
      </IconWrapper>
      <VisuallyHidden>Edit the generative art</VisuallyHidden>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border: 0px solid var(--color-text);
  border-radius: 8px;
  transform: translate(50px, -80px);
  pointer-events: auto;
  opacity: 0.5;
  transition: opacity 400ms;
  outline-color: var(--color-primary);
  scroll-margin-top: ${MIN_DISTANCE_TO_TOP + 32}px;

  &:hover,
  &:focus-visible {
    opacity: 1;
    border-width: 1px;
    transition: opacity 200ms;
  }

  &[data-pressed='true'] {
    border-width: 3px;
    transition: opacity 400ms;
  }
`;

const IconWrapper = styled(animated.span)`
  display: block;

  svg {
    display: block;
  }
`;

export default EditButton;
