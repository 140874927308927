import React from 'react';
import { styled } from '@linaria/react';

import { clamp, clampedNormalize, roundToNearest } from '@/utils';
import useBoundingBox from '@/hooks/use-bounding-box';

import DottedBackground from '@/components/Controls/DottedControlBackground';

import ControlLabel from './ControlLabel';
import BiggerDoors from './BiggerDoors';

interface Props {
  id: string;
  label: string;
  width: number;
  height: number;
  x: number;
  y: number;
  numOfRows: number;
  numOfCols: number;
  onChange: (newPosition: { x: number; y: number }) => void;
}

const STEP = 0.05;

function ArtXYPad({
  id,
  label,
  width,
  height,
  x,
  y,
  numOfRows,
  numOfCols,
  onChange,
}: Props) {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const box = useBoundingBox(wrapperRef);

  const [isDragging, setIsDragging] = React.useState(false);

  const xInPixels = clampedNormalize(x, -1, 1, 8, width - 8);
  const yInPixels = clampedNormalize(y, -1, 1, 8, height - 8);

  React.useEffect(() => {
    if (!isDragging) {
      return;
    }

    function handleMouseUp() {
      setIsDragging(false);
    }

    function handleMouseMove(ev: MouseEvent) {
      if (!box) {
        return;
      }

      const [x, y] = [ev.clientX, ev.clientY];

      const relativeX = x - box.left;
      const relativeY = y - box.top;

      onChange({
        x: clampedNormalize(relativeX, 0, box.width, -1, 1),
        y: clampedNormalize(relativeY, 0, box.height, -1, 1),
      });
    }

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging, box, onChange]);

  function handleStartGrabbing() {
    setIsDragging(true);
  }

  function handleMouseDownInEmptySpace(ev: React.MouseEvent) {
    if (!box) {
      return;
    }

    const relativeX = ev.clientX - box.left;
    const relativeY = ev.clientY - box.top;

    onChange({
      x: clampedNormalize(relativeX, 0, box.width, -1, 1),
      y: clampedNormalize(relativeY, 0, box.height, -1, 1),
    });

    setIsDragging(true);
  }

  function handleTouchMove(ev: React.TouchEvent) {
    if (!box) {
      return;
    }

    ev.preventDefault();
    const touch = ev.touches[0];
    const [x, y] = [touch.clientX, touch.clientY];

    const relativeX = x - box.left;
    const relativeY = y - box.top;

    onChange({
      x: clampedNormalize(relativeX, 0, box.width, -1, 1),
      y: clampedNormalize(relativeY, 0, box.height, -1, 1),
    });
  }

  function handleKeyDown(ev: React.KeyboardEvent) {
    if (ev.key === 'ArrowUp') {
      // Don't allow the page to scroll up/down
      ev.preventDefault();

      onChange({
        x,
        y: roundToNearest(clamp(y - STEP, -1, 1), STEP),
      });
    } else if (ev.key === 'ArrowLeft') {
      onChange({
        x: roundToNearest(clamp(x - STEP, -1, 1), STEP),
        y,
      });
    } else if (ev.key === 'ArrowRight') {
      onChange({
        x: roundToNearest(clamp(x + STEP, -1, 1), STEP),
        y,
      });
    } else if (ev.key === 'ArrowDown') {
      // Don't allow the page to scroll up/down
      ev.preventDefault();
      onChange({
        x,
        y: roundToNearest(clamp(y + STEP, -1, 1), STEP),
      });
    }
  }

  return (
    <ControlLabel htmlFor={id}>
      {label}
      <Wrapper
        ref={wrapperRef}
        style={{
          width,
          height,
          '--cursor': isDragging ? 'grabbing' : 'pointer',
        }}
        onMouseDown={handleMouseDownInEmptySpace}
        onTouchMove={handleTouchMove}
      >
        <BackgroundWrapper>
          <StyledDottedBackground
            axisLines
            numOfRows={numOfRows}
            numOfCols={numOfCols}
          />
        </BackgroundWrapper>
        <HandleButton
          id={id}
          style={{
            transform: `translate(${xInPixels}px, ${yInPixels}px)`,
            '--cursor': isDragging ? 'grabbing' : 'grab',
          }}
          data-is-dragging={isDragging}
          onMouseDown={handleStartGrabbing}
          onTouchStart={handleStartGrabbing}
          onKeyDown={handleKeyDown}
        >
          <Handle />
        </HandleButton>

        <BiggerDoors />
      </Wrapper>
    </ControlLabel>
  );
}

const Wrapper = styled.div`
  --dot-color: var(--control-gray-700);
  --dot-opacity: 1;
  --axis-color: var(--control-gray-700);
  position: relative;
  cursor: var(--cursor);
  touch-action: none;
  border: 1px solid var(--color-gray-400);
  border-radius: 4px;
  overflow: hidden;
  overflow: clip;

  &:hover,
  &:has(:active) {
    --dot-color: var(--control-gray-500);
    --axis-color: var(--control-gray-500);
  }
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--control-gray-900);
  border-radius: 3px;
  border: 3px solid var(--control-gray-900);
`;

const StyledDottedBackground = styled(DottedBackground)`
  border-radius: 4px;
  border: none;

  line {
    stroke-linecap: round;
  }
`;

const HandleButton = styled.button`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  cursor: var(--cursor);

  &:focus {
    outline: none;
  }
`;

const Handle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: white;
  border: 2px solid var(--control-gray-900);
  /* Center the button around the top-left corner of the container */
  transform: translate(-50%, -50%);

  ${HandleButton}:focus &, ${HandleButton}[data-is-dragging="true"] & {
    background: var(--control-handle);
  }
  ${HandleButton}:focus-visible & {
    outline: 2px solid hsl(50deg 100% 50%);
    outline-offset: 3px;
  }
`;

export default React.memo(ArtXYPad);
