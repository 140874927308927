import * as React from 'react';
import usePartySocket from 'partysocket/react';

import { PARTYKIT_HOST } from '@/constants/constants';
import { random, sampleOne } from '@/utils';
import type {
  GenerativeArtValues,
  Linecap,
  Shape,
} from '@/types/art.types';

const ArtContext = React.createContext<{
  state: GenerativeArtValues | null;
  numOfConnections: number;
  actions: any;
}>({
  state: null,
  numOfConnections: 1,
  actions: {},
});

export const DEFAULT_VALUES: GenerativeArtValues = {
  lineWidth: 0,
  lineLength: 0.25,
  density: 55,
  numOfRows: 10,
  linecap: 'round',
  shape: 'line',
  springiness: 75,
};

type ActionType =
  | 'TWEAK_VALUE'
  | 'RANDOMIZE_VALUES'
  | 'RESET_VALUES'
  | 'RECEIVE_VALUES_FROM_SERVER';

interface Action {
  type: ActionType;
  payload: Partial<GenerativeArtValues>;
}

interface IncomingMessage {
  type: 'broadcast-values';
  numOfConnections: number;
  state: GenerativeArtValues;
}

function reducer(
  state: GenerativeArtValues | null,
  action: Action
): GenerativeArtValues | null {
  switch (action.type) {
    case 'TWEAK_VALUE':
    case 'RANDOMIZE_VALUES': {
      const newState = {
        ...state,
        ...action.payload,
      } as GenerativeArtValues;

      return newState;
    }

    case 'RECEIVE_VALUES_FROM_SERVER': {
      return action.payload as GenerativeArtValues;
    }

    case 'RESET_VALUES': {
      return DEFAULT_VALUES;
    }

    default: {
      return state;
    }
  }
}

function ArtProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, null);
  const [numOfConnections, setNumOfConnections] = React.useState(1);

  // The art is "dirty" if the current user has modified it.
  // It resets to clean when we receive an update from the server.
  // This is important to prevent an endless loop of sending/receiving updates.
  const isDirty = React.useRef(false);

  const socket = usePartySocket({
    host: PARTYKIT_HOST,
    room: 'main-party',
    onMessage(event: any) {
      const { type, numOfConnections, state } = JSON.parse(
        event.data
      ) as IncomingMessage;

      // Futureproofing: support additional message types.
      if (type !== 'broadcast-values') {
        return;
      }

      dispatch({
        type: 'RECEIVE_VALUES_FROM_SERVER',
        payload: state,
      });
      setNumOfConnections(numOfConnections);

      isDirty.current = false;
    },
  });

  const tweakValue = React.useCallback(
    (payload: Partial<GenerativeArtValues>) => {
      dispatch({ type: 'TWEAK_VALUE', payload });
      isDirty.current = true;
    },
    []
  );

  const resetValues = React.useCallback(() => {
    dispatch({ type: 'RESET_VALUES', payload: {} });
    isDirty.current = true;
  }, []);

  const randomizeValues = React.useCallback(() => {
    const payload = {
      lineWidth: random(-1, 1, { rounded: false }),
      lineLength: random(-1, 1, { rounded: false }),
      density: sampleOne([10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
      // Bias for nice-looking options:
      numOfRows: sampleOne([
        2, 3, 3, 5, 5, 7, 8, 8, 8, 8, 9, 10, 10, 10, 10, 10, 10, 10,
        10, 10, 10,
      ]),
      linecap: sampleOne(['round', 'square']),
      shape: sampleOne(['line', 'circle']),
      springiness: random(0, 100),
    } satisfies GenerativeArtValues;

    dispatch({ type: 'RANDOMIZE_VALUES', payload });
    isDirty.current = true;
  }, []);

  React.useEffect(() => {
    if (state && isDirty.current) {
      socket.send(JSON.stringify({ type: 'send-new-values', state }));
    }
  }, [state]);

  return (
    <ArtContext.Provider
      value={{
        state,
        numOfConnections,
        actions: { tweakValue, resetValues, randomizeValues },
      }}
    >
      {children}
    </ArtContext.Provider>
  );
}

export function useArt() {
  const context = React.useContext(ArtContext);

  if (!context) {
    throw new Error('useArt must be used within an ArtProvider');
  }

  return context;
}

export default ArtProvider;
