import { styled } from '@linaria/react';

const ControlLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 4px;
  border: 1px solid var(--color-gray-300);
  border-radius: 6px;
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-mono);
  text-transform: uppercase;
  user-select: none;
`;

export default ControlLabel;
