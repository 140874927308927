import { styled } from '@linaria/react';

import useHasHydrated from '@/hooks/use-has-hydrated';

function BasicDoors({ stage = 1 }: { stage?: 1 | 2 | 3 }) {
  const hasHydrated = useHasHydrated();

  const staggeredDelay = (stage - 1) * 100;
  const baseDelay = hasHydrated ? 250 : 700;
  const animationDelay = staggeredDelay + baseDelay + 'ms';

  return (
    <>
      <CoverShadow style={{ animationDelay }} />
      <TopCover style={{ animationDelay }} />
      <BottomCover style={{ animationDelay }} />
    </>
  );
}

const CoverShadow = styled.div`
  position: absolute;
  inset: 0;
  background: hsl(212deg 20% 6%);
  animation: fadeOut 900ms both;
  pointer-events: none;
`;

const Cover = styled.div`
  --duration: 400ms;
  --curve: cubic-bezier(0.44, 0.22, 0.9, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  height: 50%;
  outline: 1px solid var(--color-gray-400);
  background: var(--color-background);
  pointer-events: none;
`;

const TopCover = styled(Cover)`
  --slide-val: calc(-100% - 2px);
  top: 0;
  animation: slideToVertical var(--duration) var(--curve) both;
`;

const BottomCover = styled(Cover)`
  --slide-val: calc(100% + 2px);
  bottom: 0;
  animation: slideToVertical var(--duration) var(--curve) both;
`;

export default BasicDoors;
