import * as React from 'react';
import { styled } from '@linaria/react';

import { range } from '@/utils';

import ControlLabel from './ControlLabel';
import BasicDoors from './BasicDoors';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  min: number;
  max: number;
  step?: number;
  label: string;
  value: number;
  onChange: React.FormEventHandler<HTMLInputElement>;
  stage?: 1 | 2 | 3;
}

function ArtSlider({
  id,
  min,
  max,
  step,
  label,
  value,
  onChange,
  stage,
  ...delegated
}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  let numOfTicks =
    typeof step === 'number' ? (max - min) / step + 1 : 16;

  numOfTicks = Math.min(numOfTicks, 21);

  return (
    <ControlLabel htmlFor={id}>
      {label}
      <InputWrapper>
        <TickWrapper>
          {range(numOfTicks).map((i) => (
            <Tick key={i} />
          ))}
        </TickWrapper>
        <Slider
          id={id}
          ref={inputRef}
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
          {...delegated}
        />
        <BasicDoors stage={stage} />
      </InputWrapper>
    </ControlLabel>
  );
}

const InputWrapper = styled.div`
  --handle-size: 1.5rem;
  --handle-color: white;
  --total-height: calc(var(--handle-size) + 4px * 2);
  --track-size: 2px;
  --track-color: var(--control-gray-700);
  --tick-color: var(--control-gray-700);
  --inline-padding: 2px;
  position: relative;
  width: 100%;
  padding-inline: var(--inline-padding);
  background: var(--control-gray-900);
  border-radius: 4px;
  border: 1px solid var(--color-gray-400);
  /* Trim corners and allow covers to slide out */
  overflow: hidden;
  overflow: clip;

  &:has(input:hover, input:focus-visible) {
    --tick-color: var(--control-gray-500);
    --track-color: var(--control-gray-500);
  }

  &:has(input:focus-visible) {
    outline: 3px auto var(--color-primary);
    outline-offset: 3px;
  }
`;

const TickWrapper = styled.div`
  position: absolute;
  --side-gap: calc(
    var(--handle-size) / 2 + var(--inline-padding) - 1px
  );
  top: 2px;
  bottom: 2px;
  left: var(--side-gap);
  right: var(--side-gap);
  display: flex;
  justify-content: space-between;
  pointer-events: none;
`;

const Tick = styled.div`
  width: 2px;
  height: 100%;
  background: linear-gradient(
    to bottom,
    var(--tick-color) 0% 36%,
    transparent 36% 64%,
    var(--tick-color) 64% 100%
  );
  border-radius: 4px;
`;

const Slider = styled.input`
  display: block;
  width: 100%;
  height: var(--total-height);
  background: transparent;
  border: none;
  appearance: none;
  cursor: pointer;

  &:active {
    cursor: grabbing;
  }

  &:focus-visible {
    outline: none;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    height: var(--handle-size);
    width: var(--handle-size);
    border-radius: 50px;
    background: var(--handle-color);
    transform: translateY(calc(-50% + (var(--track-size) / 2)));
    border: 2px solid var(--control-gray-900);

    input:focus-visible & {
      outline: 3px auto var(--color-primary);
      outline-offset: 4px;
    }

    &:active {
      cursor: grabbing;
    }
  }

  &::-webkit-slider-runnable-track {
    height: var(--track-size);
    background-color: var(--track-color);
    border-radius: calc(var(--track-size) / 2);
  }

  &::-moz-range-track {
    height: var(--track-size);
    background-color: var(--control-gray-700);
    border-radius: calc(var(--track-size) / 2);
  }
`;

export default React.memo(ArtSlider);
