/*
  This component works by changing the background color of the <html> element, so that when the user tries to scroll beyond the page bounds, the correct color is shown.

  It only sets a single color for both top and bottom, because the alternative is a flickery mess.

  This *could* be a custom hook, but then the parent component would need to become a Client Component, and I generally want to manage overscroll colors at the page level. So I'm keeping it as a component for now.
*/
'use client';

import * as React from 'react';

import { useColorMode } from '@/components/UserPreferencesProvider';

interface Props {
  color?: string;
  lightColor?: string;
  darkColor?: string;
}

function OverscrollColor({ color, lightColor, darkColor }: Props) {
  const colorMode = useColorMode();

  React.useEffect(() => {
    let actualColor = color;
    if (colorMode === 'light' && lightColor) {
      actualColor = lightColor;
    } else if (colorMode === 'dark' && darkColor) {
      actualColor = darkColor;
    }

    if (!actualColor) {
      return;
    }

    document.documentElement.style.setProperty(
      'background',
      actualColor
    );
  }, [color, lightColor, darkColor, colorMode]);

  // When the component unmounts, unset the background color
  React.useEffect(() => {
    return () => {
      document.documentElement.style.removeProperty('background');
    };
  }, []);

  return null;
}

export default OverscrollColor;
